import React from 'react';
import { useSelector } from 'react-redux';
import camelCaseKeys from 'camelcase-keys';

import ExpressCheckout from 'src/shoppe/compositions/ExpressCheckout';
import { IAccountV2, ICartV2 } from 'src/explore/types/shoppe';

import styles from './index.module.sass';

const ExpressCheckoutScene = () => {
  // The type of state is IStore but we need to use any here
  // camelCaseKeys is written to return the same type as the input
  // TODO: re-write camelCaseKeys to return a new type that is the camelized version of the input
  const account = useSelector<any, IAccountV2>(( state ) =>
    camelCaseKeys( state.data.account?.data, { deep: true })
  );
  const cart = useSelector<any, ICartV2>(( state ) =>
    camelCaseKeys( state.data.cart?.data, { deep: true })
  );

  if ( !cart ) return null;

  return (
    <div className={styles.container}>
      <ExpressCheckout cart={cart} account={account} />
    </div>
  );
};

export default ExpressCheckoutScene;
